/* Dashboard */
.dashboard {

    .summary {
        position: relative;

        .title {
            font-size: 20px;
        }

        .detail {
            color: $textSecondaryColor;
            display: block;
            margin-top: 10px;
        }

        .count {
            color: #37474F;
            position: absolute;
            top: 10px;
            right: 10px;
            font-size: 24px;
            padding: 2px 7px;
            /* padding: 2px; */
            border-radius: 6px;

            &.visitors {
                background-color: #20d077;
            }

            &.purchases {
                background-color: #f9c851;
            }

            &.revenue {
                background-color: #CFD8DC;
            }
        }
    }

    .highlight-box {
        height: 100px;
        display: flex;
        @include clearfix();

        .initials {
            height: 100%;
            width: 50%;
            text-align: center;
            padding: 1em;

            >span {
                font-size: 48px;
            }
        }

        .highlight-details {
            height: 100%;
            background-color: #ffffff;
            width: 50%;
            padding: 1em;

            i {
                font-size: 18px;
                vertical-align: middle;
                margin-right: .5em;
            }

            .count {
                color: $textSecondaryColor;
                font-size: 36px;
                margin-top: 4px;
                display: block;
            }
        }
    }

    .task-list {
        list-style-type: none;
        margin: 0;
        padding: 0;

        li {
            padding: .5em .25em;
            border-bottom: 1px solid $dividerColor;
            @include clearfix();
        }

        .p-checkbox {
            vertical-align: middle;
            margin-right: .5em;
        }

        .task-name {
            vertical-align: middle;
        }

        i {
            float: right;
            font-size: 24px;
            color: $textSecondaryColor;
        }

        .p-panel-content {
            min-height: 256px;
        }
    }

    .contact-form {
        .p-panel-content {
            min-height: 256px;
        }
    }

    .contacts {

        ul {
            list-style-type: none;
            padding: 0;
            margin: 0;

            li {
                border-bottom: 1px solid $dividerColor;

                button {
                    padding: 9px;
                    width: 100%;
                    box-sizing: border-box;
                    text-decoration: none;
                    position: relative;
                    display: block;
                    border-radius: 2px;
                    transition: background-color .2s;

                    .name {
                        position: absolute;
                        right: 10px;
                        top: 10px;
                        font-size: 18px;
                    }

                    .email {
                        position: absolute;
                        right: 10px;
                        top: 30px;
                        font-size: 14px;
                        color: $textSecondaryColor;
                    }

                    &:hover {
                        cursor: pointer;
                        background-color: #eeeeee;
                    }
                }

                &:last-child {
                    border: 0;
                }
            }
        }

        .p-panel-content {
            min-height: 256px;
        }
    }

    .activity-list {
        list-style-type: none;
        padding: 0;
        margin: 0;

        li {
            border-bottom: 1px solid $dividerColor;
            padding: 16px 8px;

            .count {
                font-size: 24px;
                color: #ffffff;
                background-color: #007be5;
                font-weight: 700;
                padding: .25em .5em;
                display: inline-block;
                border-radius: $borderRadius;
            }
        }
    }
}