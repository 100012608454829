body {
    background-color: #EEEEEE !important;
    
}

.home {
    position: relative;
}
.copy {    
    bottom: -20px;
    left: 10px;
    position: absolute;
 }

 
.background {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 95vh;

    .elementsContainer {
        width: 100vw;
        background-color: #fff;
        height: 70vh;
        min-height: 600px;
        display: flex;
        align-items: center;
        border-radius: 30px;
        margin: 10%;
        justify-content: center;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;


        .buttonsContainer {
            padding:15px;
            height: 100%;
            width: 40%;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 33;
            align-content: center;
            flex-wrap: wrap;
            flex-direction: column;
        }
    }
}

.image {
    background: url("../../assets/images/fondo.png") 50% 50% no-repeat;
    background-position: center;
    background-size: cover;
    background-color: #FFE0B2;
    height: 100%;
    border-radius: 0px 30px 30px 0px;
    width: 60%;
}

@media (max-width: 940px) {
    .image {
        width: 0%;
    }

    .background {
        .elementsContainer {
            height: 100vh;
        }
    }
}

@media (max-height: 600px) {
    .image {
        width: 0%;
    }

    .background {
        .elementsContainer {
            .buttonsContainer {
                width: 100%;
                border-radius: 33px;
            }
        }
    }
}

.app {
    border-top: #aaa;
    border-top-width: 2px;
}

.app p {
    line-height: 18px;
    font-size: 14px;
    text-align: center;
    margin: 10px 20px;

}

.appImg {
    flex-direction: row;
    justify-content: center;
    margin: 10px 0;
    -webkit-box-orient: horizontal;
    -moz-box-orient: horizontal;

    img {
        height: 40px;
    }

    a {
        margin-right: 8px;
        height: 43.5px;
    }
}

.eventCodeContainer {
    padding: 15px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    flex-direction: column;
}

.container {
    height: 40px;
    width: 250px;
}

.character {
    font-family: "SF";
    line-height: 40px;
    font-size: 30px;
    color: rgba(0, 0, 0, 0.89);
    background-color: rgb(255, 255, 255);
    border: 1px solid gray;
    border-radius: 5px;
    margin-left: 8px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 1px 4px;
}

.character {
    font-family: "SF";
    line-height: 40px;
    font-size: 30px;
    color: rgba(0, 0, 0, 0.89);
    background-color: rgb(255, 255, 255);
    border: 1px solid gray;
    border-radius: 5px;
    margin-left: 8px;
}

.character:first-child {
    margin-left: 0;
}

.characterInactive {}

.characterSelected {
    border: 1px solid rgb(0, 0, 0);
}