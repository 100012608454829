.layout-profile {
    text-align: center;
    padding: 20px 0;

    img {
        width: 56px;
        margin: 10px;
    }

    .layout-profile-link {
        cursor: pointer;
        display: inline-block;
        margin-bottom: .75em;
        transition: color $transitionDuration;

        i {
            display: inline-block;
            font-size: 16px;
            vertical-align: middle;
            margin-left: .5em;
        }
    }

    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;

        li {
            button {
                width: 100%;
                padding: 1em;
                border: 0 none;
                border-radius: 0;
                cursor: pointer;
                transition: color $transitionDuration;

                &:hover {
                    color: $menuitemHoverColor;
                }

                span {
                    margin-left: .25em;
                    vertical-align: middle;
                }

                i {
                    vertical-align: middle;
                }
            }
        }

        &.layout-submenu-wrapper-enter,
        &.layout-submenu-wrapper-leave-to {
            max-height: 0;
        }

        &.layout-submenu-wrapper-enter-to,
        &.layout-submenu-wrapper-leave {
            max-height: 1000px;
        }

        &.layout-submenu-wrapper-leave-active {
            overflow: hidden;
            transition: max-height 0.45s cubic-bezier(0, 1, 0, 1);
        }

        &.layout-submenu-wrapper-enter-active {
            overflow: hidden;
            transition: max-height 1s ease-in-out;
        }
    }
}

.layout-sidebar-light {
    .layout-profile {
        .layout-profile-link {
            color: $menuitemColor;

            &:hover {
                color: $menuitemHoverColor;
            }
        }

        ul {
            background-color: $menuitemActiveBgColor;

            li {
                button {
                    color: $menuitemColor;

                    &:hover {
                        color: $menuitemHoverColor;
                    }
                }
            }
        }
    }
}

.layout-sidebar-dark {
    .layout-profile {
        .layout-profile-link {
            color: $menuitemDarkColor;

            &:hover {
                color: $menuitemDarkHoverColor;
            }
        }

        ul {
            background-color: $menuitemDarkActiveBgColor;

            li {
                button {
                    color: $menuitemDarkColor;

                    &:hover {
                        color: $menuitemDarkHoverColor;
                    }
                }
            }
        }
    }
}
