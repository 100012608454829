.container {
  width: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background: #9053c7;
  background: -webkit-linear-gradient(-180deg, #74b9ff, #0984e3);
  background: -o-linear-gradient(-180deg, #74b9ff, #0984e3);
  background: -moz-linear-gradient(-180deg, #74b9ff, #0984e3);
  background: linear-gradient(-180deg, #74b9ff, #0984e3);
  
}

.wrapper {
  width: 960px;
  background: #fff;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  justify-content: space-between;
  padding: 70px 70px 70px 70px;
}

.finalStep{
  padding: 0px !important;
}

.medicalWrapper {
  width: 960px;
  background: #fff;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px;

}

.medicalRecordHeader {
  display: flex;
  width: 100%;
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.stepperWrapper{
  padding-bottom: 15px;
}

.inputWrapper {
  margin: 10px 0px 10px 0px;
}

.input100 {
  font-size: 15px;
  line-height: 1.5;
  color: #3e3d3d;
  display: block;
  width: 100%;
  border: none;
  background: #e8ebec;
  height: 50px;
  padding: 0 10px 0 10px;
  border-radius: 33px !important;
}

.formBtn {
  font-size: 15px;
  line-height: 1.5;
  color: #fff;
  text-transform: uppercase;
  width: 100%;
  height: 50px;
  border: none;
  background: #74b9ff;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 25px;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
  cursor: pointer;
  border-radius: 33px;
}

.error {
  border: 1px solid red !important;
}

.errorBar {
  background-color: #f67171;
  width: 100%;
  padding: 5px;
  color: white;
  border: 1px solid #ff1956 !important;
}

input[value=""]::-webkit-datetime-edit{ color: transparent; }
input:focus::-webkit-datetime-edit{ color: #000; }