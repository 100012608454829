@import url('https://fonts.googleapis.com/css2?family=SF:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');


@font-face {
    font-family: SF;
    src: url(./assets/fonts/SFPRODISPLAYMEDIUM.OTF);
}

html {
    font-family: 'SF', Verdana;
    background-color: #eee;
}

input[type=text] {
    border-radius: 20px;
    text-align: center;
    font-family: 'SF', Verdana, Geneva, Tahoma, sans-serif;

}

input[type=password] {
    border-radius: 20px;
    text-align: center;
    font-family: 'SF', Verdana, Geneva, Tahoma, sans-serif;

}


span>.p-inputtext {
    border-radius: 20px;
    text-align: center;
    font-family: 'SF', Verdana, Geneva, Tahoma, sans-serif;
}

span>.p-dropdown {
    border-radius: 33px !important;
    font-family: 'SF', Verdana, Geneva, Tahoma, sans-serif;

}

p-grid {
    scroll-margin-top: 20px;
}

.p-button-success {
    background-color: #78909C !important;
    border: none !important;
    border-radius: 33px !important;
    font-family: 'SF', Verdana, Geneva, Tahoma, sans-serif !important;

}

.p-button-help {
    background-color: #78909C !important;
    border: none !important;
    border-radius: 33px !important;
    font-family: 'SF', Verdana, Geneva, Tahoma, sans-serif !important;


}

.p-button-danger {
    background-color: #78909C !important;
    border: none !important;
    border-radius: 33px !important;
    font-family: 'SF', Verdana, Geneva, Tahoma, sans-serif !important;


}

.p-button-rounded {
    background-color: #607D8B !important;
    border: none !important;
    border-radius: 33px !important;
    font-family: 'SF', Verdana, Geneva, Tahoma, sans-serif !important;
}

.summary {
    border: 2px solid #ddd;
    border-radius: 15px;
    background-color: #eee;
}

.revenue {
    border: 2px solid #ddd;
    border-radius: 15px;
    background-color: #fff !important;
}


th,
td {
    text-align: center;
    font-size: 0.9em;
    font-weight: 500;
    font-family: 'SF', Verdana, Geneva, Tahoma, sans-serif;
}

.dashboard {
    @media screen and (max-width: 960px) {
        .p-datatable {
            font-family: 'SF', Verdana;

            &.p-datatable-customers {

                .p-datatable-thead>tr>th,
                .p-datatable-tfoot>tr>td {
                    display: none !important;
                }

                .p-datatable-tbody>tr {
                    border-bottom: 1px solid #dee2e6;

                    >td {
                        text-align: left;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border: 0 none !important;
                        width: 100% !important;
                        float: left;
                        clear: left;
                        border: 0 none;

                        .p-column-title {
                            padding: 0.4rem;
                            min-width: 30%;
                            display: inline-block;
                            margin: -0.4rem 1rem -0.4rem -0.4rem;
                            font-weight: bold;
                        }

                        .p-progressbar {
                            margin-top: 0.5rem;
                        }
                    }
                }
            }
        }
    }
}

.floatlabel-demo {
    .p-field {
        margin-top: 2rem;
        margin-bottom: 0;
    }
}

.input-demo {
    .p-multiselect {
        min-width: 15rem;
    }

    .multiselect-custom {
        .country-item {
            display: flex;
            align-items: center;

            span.flag {
                width: 18px;
                height: 12px;
                margin-right: 0.5rem;
                margin-left: 0.5rem;
            }
        }

        .country-item-value {
            border-radius: 3px;
            display: inline-flex;
            margin-right: 0.5rem;
            background-color: #2196f3;
            color: #ffffff;
        }
    }
}

.list-demo {
    .product-name {
        font-size: 1.5rem;
        font-weight: 700;
    }

    .product-description {
        margin: 0 0 1rem 0;
    }

    .product-category-icon {
        vertical-align: middle;
        margin-right: 0.5rem;
    }

    .product-category {
        font-weight: 600;
        vertical-align: middle;
    }

    .product-list-item {
        display: flex;
        align-items: center;
        padding: 1rem;
        width: 100%;

        img {
            width: 150px;
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
            margin-right: 2rem;
        }

        .product-list-detail {
            flex: 1 1 0;
        }

        .p-rating {
            margin: 0 0 0.5rem 0;
        }

        .product-price {
            font-size: 1.5rem;
            font-weight: 600;
            margin-bottom: 0.5rem;
            align-self: flex-end;
        }

        .product-list-action {
            display: flex;
            flex-direction: column;
        }

        .p-button {
            margin-bottom: 0.5rem;
        }
    }

    .product-badge {
        border-radius: 2px;
        padding: 0.25em 0.5rem;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 12px;
        letter-spacing: 0.3px;

        &.status-instock {
            background: #c8e6c9;
            color: #256029;
        }

        &.status-outofstock {
            background: #ffcdd2;
            color: #c63737;
        }

        &.status-lowstock {
            background: #feedaf;
            color: #8a5340;
        }
    }

    .product-grid-item {
        margin: 0.5em;
        border: 1px solid #dee2e6;

        .product-grid-item-top,
        .product-grid-item-bottom {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        img {
            width: 75%;
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
            margin: 2rem 0;
        }

        .product-grid-item-content {
            text-align: center;
        }

        .product-price {
            font-size: 1.5rem;
            font-weight: 600;
        }
    }

    @media screen and (max-width: 576px) {
        .product-list-item {
            flex-direction: column;
            align-items: center;

            img {
                width: 75%;
                margin: 2rem 0;
            }

            .product-list-detail {
                text-align: center;
            }

            .product-price {
                align-self: center;
            }

            .product-list-action {
                display: flex;
                flex-direction: column;
            }

            .product-list-action {
                margin-top: 2rem;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 100%;
            }
        }
    }
}

.menu-demo {
    .stepsdemo-content {
        padding: 1em 0;

        p {
            font-weight: 400;
            display: inline-block;
            vertical-align: middle;
            font-size: 18px;
            margin: 0;
        }

        i {
            vertical-align: middle;
            font-size: 1.5em;
            margin: 0;
        }
    }

    .contextmenu-image {
        width: 100%;
    }
}

.messages-demo {
    .p-field>label {
        width: 125px;
    }

    .p-inputtext {
        margin-right: 0.5rem;
    }
}

.misc-demo {
    .badges {

        .p-badge,
        .p-tag {
            margin-right: 0.5rem;
        }

        .p-tag {
            line-height: 1;
        }
    }

    .p-button {
        margin-right: 0.5rem;
    }

    .p-overlay-badge {
        margin-right: 2rem;
    }
}

.overlay-demo {
    p {
        line-height: 1.5;
        margin: 0;
    }

    .confirmation-content {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.panel-demo {
    .p-toolbar {
        flex-wrap: wrap;
        overflow: auto;
    }

    p {
        line-height: 1.5;
        margin: 0;
    }

    .p-card {
        .p-card-title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 0;
            padding: 1rem 1rem 0 1rem;
        }

        .p-card-body {
            padding-top: 0;
        }
    }
}

.table-demo {
    .p-progressbar {
        height: 0.5rem;
        background-color: #d8dadc;

        .p-progressbar-value {
            background-color: #607d8b;
        }
    }

    .p-datatable .p-column-filter {
        display: none;
    }

    .table-header {
        display: flex;
        justify-content: space-between;
    }

    .p-datatable.p-datatable-customers {
        .p-datatable-header {
            padding: 1rem;
            text-align: left;
            font-size: 1.5rem;
        }

        .p-paginator {
            padding: 1rem;
        }

        .p-datatable-thead>tr>th {
            text-align: left;
        }

        .p-datatable-tbody>tr>td {
            cursor: auto;
        }

        .p-dropdown-label:not(.p-placeholder) {
            text-transform: uppercase;
        }
    }

    /* Responsive */
    .p-datatable-customers .p-datatable-tbody>tr>td>.p-column-title {
        display: none;
    }

    .customer-badge {
        border-radius: 2px;
        padding: 0.25em 0.5rem;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 12px;
        letter-spacing: 0.3px;

        &.status-qualified {
            background: #c8e6c9;
            color: #256029;
        }

        &.status-unqualified {
            background: #ffcdd2;
            color: #c63737;
        }

        &.status-negotiation {
            background: #feedaf;
            color: #8a5340;
        }

        &.status-new {
            background: #b3e5fc;
            color: #23547b;
        }

        &.status-renewal {
            background: #eccfff;
            color: #694382;
        }

        &.status-proposal {
            background: #ffd8b2;
            color: #805b36;
        }
    }

    .p-progressbar-value.ui-widget-header {
        background: #607d8b;
    }

    @media (max-width: 640px) {
        .p-progressbar {
            margin-top: 0.5rem;
        }
    }

    .product-image {
        width: 100px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    }

    .orders-subtable {
        padding: 1rem;
    }

    .product-badge {
        border-radius: 2px;
        padding: 0.25em 0.5rem;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 12px;
        letter-spacing: 0.3px;

        &.status-instock {
            background: #c8e6c9;
            color: #256029;
        }

        &.status-outofstock {
            background: #ffcdd2;
            color: #c63737;
        }

        &.status-lowstock {
            background: #feedaf;
            color: #8a5340;
        }
    }

    .order-badge {
        border-radius: 2px;
        padding: 0.25em 0.5rem;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 12px;
        letter-spacing: 0.3px;

        &.order-delivered {
            background: #c8e6c9;
            color: #256029;
        }

        &.order-cancelled {
            background: #ffcdd2;
            color: #c63737;
        }

        &.order-pending {
            background: #feedaf;
            color: #8a5340;
        }

        &.order-returned {
            background: #eccfff;
            color: #694382;
        }
    }

    @media screen and (max-width: 960px) {
        .p-datatable {
            &.p-datatable-customers {

                .p-datatable-thead>tr>th,
                .p-datatable-tfoot>tr>td {
                    display: none !important;
                }

                .p-datatable-tbody>tr {
                    >td {
                        text-align: left;
                        display: block;
                        border: 0 none !important;
                        width: 100% !important;
                        float: left;
                        clear: left;
                        border: 0 none;

                        .p-column-title {
                            padding: 0.4rem;
                            min-width: 30%;
                            display: inline-block;
                            margin: -0.4rem 1rem -0.4rem -0.4rem;
                            font-weight: bold;
                        }

                        .p-progressbar {
                            margin-top: 0.5rem;
                        }
                    }
                }

                colgroup {
                    display: none;
                }
            }

            .p-datatable-header {
                .table-header {
                    flex-direction: column !important;
                }
            }
        }
    }
}

.text-demo {
    .demo-container {
        border: 2px solid #dee2e6;
    }
}

.spacing-demo {
    .demo-container {
        border: 1px solid #dee2e6;
    }
}

.icons-demo {
    .icons-list {
        text-align: center;
        color: #6c757d;

        .p-md-2 {
            padding: 1em;
        }
    }

    .icons-list i {
        font-size: 1.5rem;
        margin-bottom: 0.5rem;
    }
}

.flexgrid-demo {
    .box {
        background-color: #ffffff;
        text-align: center;
        padding-top: 1rem;
        padding-bottom: 1rem;
        border-radius: 4px;
        box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
    }

    .box-stretched {
        height: 100%;
    }

    .vertical-container {
        margin: 0;
        height: 200px;
        background: #dee2e6;
        border-radius: 4px;
    }

    .nested-grid .p-col-4 {
        padding-bottom: 1rem;
    }

    .dynamic-box-enter-active,
    .dynamic-box-leave-active {
        transition: all 0.5s;
    }

    .dynamic-box-enter-from,
    .dynamic-box-leave-to {
        opacity: 0;
    }

    .dynamic-box-enter-from,
    .dynamic-box-leave-to {
        transform: translateX(30px);
    }

    p {
        margin: 0;
    }
}

.flexbox-demo {

    .p-d-flex>div,
    .box {
        background-color: #ffffff;
        text-align: center;
        padding: 1rem;
        border-radius: 4px;
        box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
    }

    .p-d-flex>div {
        width: 8rem;
    }
}

.elevation-demo {
    .box {
        min-height: 100px;
        min-width: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.25rem;
        margin: 2rem;
        border-radius: 4px;
    }

    .shadow-container {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }
}

.crud-demo {
    .table-header {
        flex-direction: column;

        @media screen and (min-width: 768px) {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: row !important;
        }
    }

    .product-image {
        width: 100px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    }

    .p-toolbar {
        display: flex;
        flex-wrap: wrap;
    }

    .p-dialog .product-image {
        width: 150px;
        margin: 0 auto 2rem auto;
        display: block;
    }

    .confirmation-content {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .product-badge {
        border-radius: 2px;
        padding: 0.25em 0.5rem;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 12px;
        letter-spacing: 0.3px;

        &.status-instock {
            background: #c8e6c9;
            color: #256029;
        }

        &.status-outofstock {
            background: #ffcdd2;
            color: #c63737;
        }

        &.status-lowstock {
            background: #feedaf;
            color: #8a5340;
        }
    }

    /* Responsive */
    .datatable-responsive .p-datatable-thead {
        display: inline block;
    }

    @media screen and (max-width: 960px) {
        .p-datatable {
            &.datatable-responsive {

                .p-datatable-thead>tr>th,
                .p-datatable-tfoot>tr>td {
                    display: none !important;
                }

                .p-datatable-tbody>tr {
                    border-bottom: 1px solid var(--surface-d);

                    >td {
                        text-align: left;
                        display: flex;
                        align-items: center;
                        border: 0 none !important;
                        width: 100% !important;
                        float: left;
                        clear: left;
                        border: 0 none;

                        .p-column-title {
                            padding: 0.4rem;
                            min-width: 30%;
                            display: inline-block;
                            margin: -0.4rem 1rem -0.4rem -0.4rem;
                            font-weight: bold;
                        }

                        .p-progressbar {
                            margin-top: 0.5rem;
                        }

                        .actions {
                            display: flex;
                            flex-grow: 1;
                            justify-content: center;
                        }
                    }
                }
            }
        }
    }
}

.docs {
    h1 {
        margin-top: 28px;
        margin-bottom: 14px;
    }

    p {
        line-height: 1.5;
    }
}

pre[class*="language-"] {

    &:after,
    &:before {
        box-shadow: none;
    }

    padding: 0 !important;
    background: transparent;
    overflow: visible;

    >code {
        box-shadow: none !important;
        border-left: 10px solid #0388e5;
    }
}

.custom-marker {
    display: flex;
    width: 2rem;
    height: 2rem;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    border-radius: 50%;
    z-index: 1;
}

.p-timeline-event-content,
.p-timeline-event-opposite {
    line-height: 1;
}

.icon-card {
    font-size: 25px;
}

.social-button {
    display: flex;
    align-items: center;
}

.social-text {
    margin-right: 1em;
    font-size: 0.9em;
    color: #6c7581;
}

.code-text-container {
    height: 50px;
    width: 300px;
}

.code-text-character {
    line-height: 50px;
    font-size: 36px;
    color: #EF5350;
    background-color: rgba(255, 255, 255, 0.2);
    border: 1px solid #ced4da;
    border-radius: 8px;
    margin-left: 8px;
}

.code-text-character:first-child {
    margin-left: 0;
}

.code-text-character--inactive {
    background-color: rgba(255, 255, 255, 0.1);
}

.code-text-character--selected {
    border: 1px solid white;
}

@media screen and (max-width: 960px) {
    .customized-timeline {
        .p-timeline-event:nth-child(even) {
            flex-direction: row !important;

            .p-timeline-event-content {
                text-align: left !important;
            }
        }

        .p-timeline-event-opposite {
            flex: 0;
        }

        .p-card {
            margin-top: 1rem;
        }
    }
}

.unselectable {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}


/* width */

::-webkit-scrollbar {
    width: 10px;
}

/* Track */

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */

::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
    background: #555;
}

#zDialog {
    z-index: 9000 !important;
}

.has-alert-row-2 {
    border: 2px solid #af3e3e;
    color: #cb0808 !important;
    font-weight: 600;
    background-color: #f0d6d6 !important;
}

.stat {
    background-color: #FAFAFA !important;
    border-bottom: 1px solid #eee;
    margin-bottom: 5px;
    padding: 10px;
    border-radius: 10px;
}


.statTitle {
    font-size: 20px;
}

.statData {
    font-size: 17px;
    font-weight: 600;
    color: #ffffff !important;
    background-color: #5C6BC0;
    padding: 7px;
    margin-left: 10px;
    border-radius: 10px;
}

.statLabel {
    font-size: 12px;
    font-weight: 600;
    color: #5f5454 !important;
    padding: 7px;
    margin-left: 10px;
}

.wait {
    font-size: 18px;
    font-weight: 200;
    color: #565656 !important;
    padding: 7px;
    margin-left: 10px;
}