.layout-sidebar {
    transition: left $transitionDuration;
    left: 0px;
}

@media (min-width: 1025px) {
    .layout-topbar {
        left: 0;
    }

    .layout-wrapper {
        &.layout-overlay {
            .layout-main,
            .layout-footer {
                margin-left: 0;
            }

            &.layout-active {
                .layout-sidebar {
                    left: 0;
                }

                .layout-topbar {
                    left: 250px;
                }
            }
        }

        &.layout-static {
            .layout-main,
            .layout-footer {
                margin-left: 0px;
            }

            &.layout-active {
                .layout-topbar {
                    left: 250px;
                }

                .layout-main,
                .layout-footer {
                    margin-left: 250px;
                }
            }
        }
    }
}

@media (max-width: 1024px) {
    .layout-sidebar {
        margin-top: 50px;
    }

    .layout-wrapper {
        .layout-topbar {
            left: 0;
        }

        .layout-main,
        .layout-footer {
            margin-left: 0;
        }

        &.layout-active {
            .layout-sidebar {
                left: 0;
            }
        }
    }

    .body-overflow-hidden {
        overflow: hidden;
    }
}
