/* Typography */
.card {
    background-color: #ffffff;
    padding: 1em;
    margin-bottom: 16px;
    border-radius: $borderRadius;

    &.card-w-title {
        padding-bottom: 2em;
    }
}
